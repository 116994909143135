import SimpleSpinner from "./SimpleSpinner";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function GenerateButton({
  generatedCount,
  generateReply,
  disabled,
}) {
  const [isGenerating, setGenerating] = useState(false);
  const tokens = useSelector((state) => state.subscription.tokens);

  async function onGenerateReply() {
    setGenerating(true);
    await generateReply();
    setGenerating(false);
  }

  return (
    <button
      className="main-button mx-2 inline-block border bg-primary text-center text-white disabled:cursor-not-allowed disabled:opacity-50"
      onClick={() => onGenerateReply()}
      disabled={disabled || generatedCount > 3 || isGenerating || !tokens}
    >
      {isGenerating && (
        <div className="mr-3 inline-block">
          <SimpleSpinner />
        </div>
      )}
      Generate Reply
    </button>
  );
}
