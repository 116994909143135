import React, { useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import "./SavingsCalculator.scss";
import { CustomTextField } from "../common/CustomTextField";
import { PricingCard } from "../common/PricingCard";

const subscriptionPlans = [
  {
    title: "Small",
    price: 10,
    fullPrice: "20$/month",
    replies: 100,
    timeSaved: "",
    moneySaved: 0,
  },
  {
    title: "Medium",
    price: 15,
    fullPrice: "30$/month",
    replies: 300,
    timeSaved: "",
    moneySaved: 0,
  },
  {
    title: "Large",
    price: 25,
    fullPrice: "50$/month",
    replies: 500,
    timeSaved: "40.28 hours",
    moneySaved: 975,
  },
];
export default function SavingsCalculator() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [reviews, setReviews] = useState(500);
  const [minutesSpent, setMinutesSpent] = useState(5);
  const [monthlySalary, setMonthlySalary] = useState(1000);

  const [bestPlanForUser, setBestPlanForUser] = useState(subscriptionPlans[2]);

  const calculateSubscription = () => {
    let bestPlan;
    // loop through the subscription plans and find the one with the lowest cost per hour
    switch (true) {
      case reviews <= 100:
        bestPlan = { ...subscriptionPlans[0] };
        break;
      case reviews <= 300:
        bestPlan = { ...subscriptionPlans[1] };
        break;
      default:
        bestPlan = { ...subscriptionPlans[2] };
        break;
    }
    const totalTime = reviews * minutesSpent;
    const totalReplyBuddyTime = reviews * (10 / 60);
    const timeSavedInMinutes = totalTime - totalReplyBuddyTime;
    const timeSavedInHours = Math.round((timeSavedInMinutes / 60) * 100) / 100;
    bestPlan.timeSaved =
      timeSavedInMinutes >= 60
        ? `${timeSavedInHours} hours`
        : `${timeSavedInMinutes} minutes`;
    let extraTokensPrice = 0;
    if (reviews > 500) {
      extraTokensPrice = (reviews - 500) * 0.15;
    }

    bestPlan.moneySaved = monthlySalary - (bestPlan.price + extraTokensPrice);

    setBestPlanForUser(bestPlan);
  };

  return (
    <div className="calculator">
      <h1 className="calculator-title">
        Calculate how much money would you save with{" "}
        <span className="highlight">ReplyBuddy</span>
      </h1>
      <Grid
        container
        className={`calculator-container ${isMobile ? "mobile" : ""} `}
      >
        <Grid
          className="calculator-content-wrapper"
          item
          xs={12}
          md={4}
          lg={4}
          xl={4}
        >
          <div className="calculator-content reviews">
            <h1 className="content-title">Reviews replied per month</h1>
            <CustomTextField
              id="outlined-number"
              className="numeric-input"
              label="Reviews"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              focusColor="#642ae5"
              value={reviews}
              onChange={(e) => setReviews(e.target.value)}
            />
          </div>
        </Grid>
        <Grid
          className="calculator-content-wrapper"
          item
          xs={12}
          md={4}
          lg={4}
          xl={4}
        >
          <div className="calculator-content time">
            <h1 className="content-title">Time spent on one review</h1>
            <CustomTextField
              id="outlined-number"
              className="numeric-input"
              label="Minutes"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              focusColor="#642ae5"
              value={minutesSpent}
              onChange={(e) => setMinutesSpent(e.target.value)}
            />
          </div>
        </Grid>
        <Grid
          className="calculator-content-wrapper"
          item
          xs={12}
          md={4}
          lg={4}
          xl={4}
        >
          <div className="calculator-content salary">
            <h1 className="content-title">Salary of employee per month</h1>
            <CustomTextField
              id="outlined-number"
              className="numeric-input"
              label="Salary in USD $"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              focusColor="#642ae5"
              value={monthlySalary}
              onChange={(e) => setMonthlySalary(e.target.value)}
            />
          </div>
        </Grid>

        <Grid
          className="calculator-button-wrapper"
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          <button
            onClick={() => calculateSubscription()}
            className="main-button calculate-button"
          >
            Calculate
          </button>
        </Grid>
        <Grid
          className="calculator-result-wrapper"
          item
          xs={12}
          md={8}
          lg={8}
          xl={8}
        >
          <h1 className="content-result">
            With <span>ReplyBuddy</span> you would save
          </h1>
          <h1 className="content-result">
            <span>{bestPlanForUser.moneySaved}$</span> and
            <span> {bestPlanForUser.timeSaved}</span>
          </h1>
          <h1 className="content-result">every month</h1>
        </Grid>
        <Grid
          className="calculator-result-wrapper"
          item
          xs={12}
          md={4}
          lg={4}
          xl={4}
        >
          <PricingCard
            title={bestPlanForUser.title}
            price={bestPlanForUser.price}
            fullPrice={bestPlanForUser.fullPrice}
            timeInterval="/month"
            replies={bestPlanForUser.replies}
            buttonText="Subscribe"
            facts={["Cancel anytime"]}
            className="result-subscription"
          />
        </Grid>
      </Grid>
    </div>
  );
}
