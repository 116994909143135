import React, { useState } from "react";
import "./WorksStepper.scss";
import { Grid, useMediaQuery } from "@mui/material";

export default function WorksStepper() {
  const [step, setStep] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div className={`works-stepper-container ${isMobile ? "mobile" : ""}`}>
      <h1 className="works-stepper-header">
        How to use the <span className="highlight">chrome extenstion</span>
      </h1>
      <Grid container className="works-steps-grid">
        <Grid
          className="works-steps-container"
          item
          xs={12}
          md={4}
          lg={4}
          xl={4}
        >
          <div
            onClick={() => setStep(0)}
            className={`works-step ${step === 0 ? "active" : ""}`}
          >
            <div className="works-step-content">
              <h1>Get chrome extension</h1>
              <p>Install the chrome extension</p>
            </div>
            <div className="works-step-number one"></div>
          </div>
          <div
            onClick={() => setStep(1)}
            className={`works-step ${step === 1 ? "active" : ""}`}
          >
            <div className="works-step-content">
              <h1>Login</h1>
              <p>Login with your ReplyBuddy account</p>
            </div>
            <div className="works-step-number two"></div>
          </div>
          <div
            onClick={() => setStep(2)}
            className={`works-step ${step === 2 ? "active" : ""}`}
          >
            <div className="works-step-content">
              <h1>Select text</h1>
              <p>Select the text you want answers generated to</p>
            </div>
            <div className="works-step-number three"></div>
          </div>
          <div
            onClick={() => setStep(3)}
            className={`works-step ${step === 3 ? "active" : ""}`}
          >
            <div className="works-step-content">
              <h1>Generate response</h1>
              <p>Configure your reply settings and generate</p>
            </div>
            <div className="works-step-number four"></div>
          </div>
        </Grid>
        <Grid
          className="works-steps-information-container"
          item
          xs={12}
          md={8}
          lg={8}
          xl={8}
        >
          <div className="works-steps-information">
            {step === 0 && (
              <img src={require("../../images/browser-step-1.png")}></img>
            )}
            {step === 1 && (
              <>
                <img src={require("../../images/browser-step-2.png")}></img>
              </>
            )}
            {step === 2 && (
              <>
                <img src={require("../../images/browser-step-3.png")}></img>
              </>
            )}
            {step === 3 && (
              <>
                <img src={require("../../images/browser-step-4.png")}></img>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
