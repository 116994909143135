import React from "react";
import "./PricingCard.scss";
import { useMediaQuery } from "@mui/material";

export const PricingCard = ({
  title,
  price,
  fullPrice,
  timeInterval,
  replies,
  facts,
  buttonText,
  buttonFunction,
  currency = "$",
  noTitleUnderLine,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div className={`pricing-card ${isMobile ? "mobile" : ""}`}>
      {title && (
        <div
          className={`pricing__title ${noTitleUnderLine ? "no-underline" : ""}`}
        >
          {title}
        </div>
      )}
      <div className="price-container">
        {fullPrice && <span className="full-price">{fullPrice}</span>}
        {price && (
          <div className="price">
            <span>{currency}</span>
            <span className="pricing__price">{price}</span>
            <span>{timeInterval}</span>
          </div>
        )}
      </div>
      <div className="pricing__text">
        {replies && (
          <span>
            <span className="number-of-replies">{replies}</span> replies
          </span>
        )}
        {facts &&
          facts.map((fact, index) => <p key={`fact${index}`}>{fact}</p>)}
      </div>
      {buttonText && (
        <button
          onClick={buttonFunction}
          className="main-button purchase-button bg-primary text-white"
        >
          {buttonText}
        </button>
      )}
    </div>
  );
};
