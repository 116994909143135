import { IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./GeneratedResult.scss";

export const GeneratedResult = ({ text }) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setDisplayText(text.slice(0, index));
      index++;
      if (index > text.length) {
        clearInterval(intervalId);
      }
    }, 30);
    return () => clearInterval(intervalId);
  }, [text]);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="result-container">
      <textarea className="result-textarea" value={displayText} readOnly />
      <div className="result-textarea-buttons">
        <IconButton onClick={() => handleCopyClick()}>
          <ContentCopyIcon />
        </IconButton>
      </div>
    </div>
  );
};
