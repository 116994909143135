import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import AppThumbnail from "./AppThumbnail";
import "./Platforms.scss";

export const Platforms = () => {
  const apps = useSelector((state) => state.platforms.apps);
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div className={` platforms ${isMobile ? "mobile" : ""}`}>
      <h1 className="platforms-header">Your platforms</h1>
      <Grid container justifyContent="center" className="platforms-container">
        {apps.map((app) => {
          return (
            <Grid className="app-wrapper" key={app._id} item xs={12} md={3}>
              <AppThumbnail app={app} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
