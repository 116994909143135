import { MenuItem, Select, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import "./GenerateReplyForm.scss";
import { CustomTextarea } from "./CustomTextarea";
import { GeneratedResult } from "./GeneratedResult";
import { CustomTextField } from "../../common/CustomTextField";
import GenerateButton from "../../common/GenerateButton";
import { generateReplyPlayground } from "../../../utils/apiRequests";

export const GenerateReplyForm = ({}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [replyType, setReplyType] = useState("review");
  const [replyTone, setReplyTone] = useState("customer-support");
  const [extraInformation, setExtraInformation] = useState("");
  const [message, setMessage] = useState("");
  const [generatedReply, setGeneratedReply] = useState("");
  const [modelType, setModelType] = useState("chat");

  const handleGenerateReply = async () => {
    const resp = await generateReplyPlayground({
      replyType,
      replyTone,
      extraInformation,
      modelType,
      userText: message,
    });
    let replies = (resp.dbText && resp.dbText.generatedReplies) || [];
    let lastReply = replies[replies.length - 1] || {};
    if (!lastReply.replyText) {
      return;
    }
    setGeneratedReply(lastReply.replyText);
  };

  return (
    <div className={`generate-reply-form ${isMobile ? "mobile" : ""}`}>
      <CustomTextarea
        label="Message"
        placeholder="Enter a message from a customer"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        name="message"
      />
      <div className="generate-reply-form__selectors">
        <Select
          value={replyType}
          onChange={(e) => setReplyType(e.target.value)}
          className="generate-reply-form__selector"
        >
          <MenuItem value="review">Review</MenuItem>
          <MenuItem value="email">Email</MenuItem>
          <MenuItem value="photo-comments">Photo comments</MenuItem>
          <MenuItem value="user-chat">User chat</MenuItem>
        </Select>

        <Select
          value={replyTone}
          onChange={(e) => setReplyTone(e.target.value)}
          className="generate-reply-form__selector"
        >
          <MenuItem value="official">Official</MenuItem>
          <MenuItem value="friendly">Friendly / Indirect</MenuItem>
          <MenuItem value="customer-support">Customer support</MenuItem>
          <MenuItem value="funny">Funny / Ironic</MenuItem>
        </Select>
      </div>
      <CustomTextField
        onChange={(e) => setExtraInformation(e.target.value)}
        label="Information to include"
        variant="outlined"
        value={extraInformation}
        focusColor="#642ae5"
      />
      <div className="generate-reply-form__selectors">
        <GenerateButton
          disabled={message.length <= 0}
          generatedCount={0}
          generateReply={handleGenerateReply}
        />
        <Select
          value={modelType}
          className="generate-reply-form__selector"
          onChange={(e) => setModelType(e.target.value)}
        >
          <MenuItem value="chat">Gpt 3.5</MenuItem>
          <MenuItem value="davinci">Gpt 3</MenuItem>
        </Select>
      </div>

      <GeneratedResult text={generatedReply} />
    </div>
  );
};
