import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addMailMemory,
  replyToMail,
  generateMailReply,
} from "../../utils/apiRequests";
import { consumeToken } from "../../utils/store-slices/subscriptionSlice";
import { isPaidUser } from "../../utils/firebaseUtil";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Paper,
  Grid,
} from "@mui/material";
import EmailReceived from "./EmailReceived";
import EmailReplied from "./EmailReplied";
import ReplyInput from "../common/ReplyInput";
import ReplyButton from "../common/ReplyButton";
import GenerateButton from "../common/GenerateButton";

const gridItemStyle = {
  overflow: "hidden",
  height: "25px",
  textOverflow: "ellipsis",
  paddingLeft: "10px",
};

export default function EmailElement({ email }) {
  const [open, setOpen] = useState(false);
  const [textToReply, setTextToReply] = useState("");
  const [devReplyText, setDevReplyText] = useState("");
  const [devReplyDate, setDevReplyDate] = useState("");
  const [generatedCount, setGeneratedCount] = useState(0);
  const [similarMemories, setSimilarMemories] = useState(null);
  const subscriptionType = useSelector((state) => state.subscription.type);
  let { platformId } = useParams();

  const dispatch = useDispatch();

  function findValue(name) {
    const result = email.payload.headers.find((e) => e.name === name);
    return result?.value || "";
  }

  function isGeneratedTextOverflown() {
    return (textToReply || "").length > 700;
  }

  async function setReviewElement(reviewJson) {
    let devComment = reviewJson.developerComment;
    if (devReplyText || devComment) {
      await setDevReplyText(devReplyText || devComment["text"]);
      await setDevReplyDate(
        devReplyDate || devComment["lastModified"]["seconds"]
      );
    } else {
      await setGeneratedReply(textToReply, reviewJson["generatedReplies"]);
    }
    // await setSentiment(sentiment || userComment["sentiment"]);
    if (reviewJson["generatedReplies"]) {
      let genReplies = reviewJson["generatedReplies"];
      let lastGenReply = genReplies[genReplies.length - 1];
      await setSimilarMemories(
        similarMemories || lastGenReply["similarMemories"]
      );
      await setGeneratedCount(
        generatedCount || reviewJson["generatedReplies"].length
      );
    }
  }

  async function setGeneratedReply(defaultValue, generatedReplies) {
    if (generatedReplies && generatedReplies.length > 0) {
      let lastGeneratedReply = generatedReplies[generatedReplies.length - 1];
      await setTextToReply(defaultValue || lastGeneratedReply["replyText"]);
    }
  }

  async function handleGenerateReply() {
    let response = await generateMailReply(email, platformId);
    await setReviewElement(response);
    await setGeneratedReply(null, response["generatedReplies"]);
    dispatch(consumeToken());
  }

  async function handleReplyToMail() {
    let dbMail = await replyToMail(platformId, email, textToReply);
    // await addMailMemory(dbMail.email.id, textToReply);
    setDevReplyText(textToReply);
    setTextToReply("");
  }

  return (
    <Paper
      elevation={open ? 12 : 0}
      sx={{
        margin: open ? "25px 0 35px" : "",
        borderBottom: open ? "" : "1px solid grey",
        borderRadius: open ? "5px" : "0px",
      }}
    >
      <Accordion
        key={email["id"]}
        style={{
          boxShadow: "none",
        }}
        onChange={() => setOpen(!open)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            backgroundColor: open ? "#804eea" : "",
            color: open ? "white" : "",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={3}
              sx={{
                ...gridItemStyle,
                whiteSpace: "nowrap",
              }}
            >
              {findValue("From") || findValue("from")}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                ...gridItemStyle,
                whiteSpace: "nowrap",
                fontWeight: "bold",
                color: "black",
              }}
            >
              {findValue("Subject")}
            </Grid>
            <Grid item xs={6} sx={gridItemStyle}>
              {email.snippet}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            whiteSpace: "break-spaces",
            padding: "20px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <EmailReceived recievedText={email?.messageBody || email.snippet} />
          {devReplyText && <EmailReplied replyText={devReplyText} />}
          <div style={{ display: "flex", flexDirection: "column" }}>
            {isPaidUser(subscriptionType) && (
              <GenerateButton
                generatedCount={generatedCount}
                generateReply={handleGenerateReply}
              />
            )}
            <div style={{ display: "flex", marginTop: "10px" }}>
              <ReplyInput
                textToReply={textToReply}
                onReplyChanged={(val) => setTextToReply(val)}
                isGeneratedTextOverflown={isGeneratedTextOverflown}
                maxLength={700}
              />
              <ReplyButton
                sendReply={handleReplyToMail}
                isGeneratedTextOverflown={isGeneratedTextOverflown}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
