import React, { useEffect, useState } from "react";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import { auth, isAdmin, isLoggedIn } from "../../utils/firebaseUtil";
import SimpleDropdown from "../common/SimpleDropdown";
import { signOut } from "firebase/auth";
import { getActiveSubscription } from "../../utils/apiRequests";
import {
  setSubscriptionType,
  setTokens,
} from "../../utils/store-slices/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import OutOfTokensModal from "../dashboard/OutOfTokensModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import "./Navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { extDomain } from "../../config";

export default function Navbar() {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const onCollapseHandler = () => setIsCollapsed(!isCollapsed);
  const [showOutOfTokensModal, setShowOutOfTokensModal] = useState(false);
  const tokens = useSelector((state) => state.subscription.tokens);
  const subscriptionType = useSelector((state) => state.subscription.type);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn()) {
      getActiveSubscription().then((subscription) => {
        dispatch(setSubscriptionType(subscription.type));
        dispatch(setTokens(subscription.tokens));
      });
    }
  }, [user]);

  useEffect(() => {
    setShowOutOfTokensModal(tokens === 0);
  }, [tokens]);

  return (
    <nav className="nav-bar">
      <a className="flex flex-shrink-0 items-center text-black" href="/">
        <img
          src={require("../../images/logo.png")}
          alt="logo"
          className="w-10"
        />
        <span className="px-4 font-lato text-[14px] font-bold text-primary sm:text-[18px]">
          ReplyBuddy.ai
        </span>
      </a>
      <div className="spacer:hidden" onClick={onCollapseHandler}>
        <img src={require("../../images/Menu.png")} alt="menu" />
      </div>
      <div
        className={
          isCollapsed
            ? "buttons block w-full flex-grow spacer:flex spacer:w-auto spacer:items-center"
            : " hidden  spacer:flex spacer:grow spacer:items-center spacer:justify-center"
        }
      >
        <div className="text-sm sm:flex-grow spacer:mr-auto spacer:ml-8">
          {isAdmin(subscriptionType) && (
            <span
              onClick={() => navigate("/fine-tuning")}
              className="main-button border"
            >
              Fine Tuning
            </span>
          )}
        </div>
        {!isLoggedIn() && (
          <>
            <span
              className="main-button mx-2"
              onClick={() => navigate("/login")}
            >
              Login
            </span>
            <span
              className="main-button bg-primary text-white"
              onClick={() => navigate("/signup")}
            >
              Sign up
            </span>
            <span
              className="main-button ml-2  bg-primary text-white"
              onClick={() => window.open(extDomain, "_blank")}
            >
              <FontAwesomeIcon icon={faAdd} className="mr-3" /> Add extension
            </span>
          </>
        )}
        {isLoggedIn() && (
          <div className="nav-logged-in-buttons">
            <span className=" rounded-2xl border bg-gray-600 px-3 py-1 text-white">
              Tokens: {tokens}
            </span>
            <span
              className="main-button bg-primary text-white"
              onClick={() => navigate("/apps")}
            >
              Dashboard
            </span>
            <SimpleDropdown
              label="Account"
              menuItems={[
                {
                  label: "My profile",
                  handleClick: () => navigate("/my-profile"),
                },
                {
                  label: "Logout",
                  handleClick: () => signOut(auth),
                },
              ]}
            />
            <OutOfTokensModal
              showModal={showOutOfTokensModal}
              setShowModal={setShowOutOfTokensModal}
            />
          </div>
        )}
      </div>
    </nav>
  );
}
