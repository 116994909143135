import React from "react";
import { toDateTimeString } from "../../utils/dataParser";

export default function ReviewReply({ lastModifiedSeconds, text }) {
  if (text) {
    return (
      <>
        <div className="rounded-lg border border-purple-200 bg-purple-100 p-2">
          {text}
        </div>
        <span className="grid place-content-end text-[12px] italic">
          {toDateTimeString(lastModifiedSeconds)}
        </span>
      </>
    );
  }
}
