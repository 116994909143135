import { useMediaQuery } from "@mui/material";
import ImageTextContent from "../common/ImageTextContent";
import "./Benefits.scss";
import Facts from "./Facts";

export default function Benefits() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const reduceDescription =
    "By using Replybuddy you can expect a significant reduction in both time and cost for your project. Check out how much would you save by using our calculator";
  const presonalizedDescription =
    "Our service is designed to provide personalized responses to your customers. By analyzing the content of your customer's message, our artificial intelligence engine generates a response that is customized for their needs.";
  const constantlyLearningDescription =
    "Our AI-powered solution is designed to constantly learn and adapt to your needs. It will use machine learning algorithms to analyze data and improve its performance over time, ensuring that it stays up-to-date and provides the most accurate and effective results for you.";
  return (
    <div className={`benefits-container ${isMobile ? "mobile" : ""}`}>
      <div className="benefits-content">
        <h1 className="benefits-title">
          We <span className="highlight">boost reviews</span> replying process
        </h1>
        <p className="benefits-description">
          Replybuddy was built on the most advanced Artificial Intelligency
          language model to help you revolutionize how your business answers to
          your customer reviews. It uses the same technology as ChatGPT.
        </p>
        <Facts />

        <ImageTextContent
          title="Reduced Time & Cost"
          text={reduceDescription}
          imageUrl={require("../../images/benefits1.png")}
          imagePosition="left"
        />
        <ImageTextContent
          title="Personalized Responses"
          text={presonalizedDescription}
          imageUrl={require("../../images/benefits2.png")}
          imagePosition="right"
        />
        <ImageTextContent
          title="Constantly Learning Algorithm"
          text={constantlyLearningDescription}
          imageUrl={require("../../images/benefits3.png")}
          imagePosition="left"
        />
      </div>
    </div>
  );
}
