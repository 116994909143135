import React, { useEffect, useState } from "react";
import ReviewUserInfo from "./ReviewUserInfo";
import ReviewStars from "./ReviewStars";
import MessageSentiment from "./MessageSentiment";
import ReviewText from "./ReviewText";
import ReviewReply from "./ReviewReply";
import ReplyInput from "../common/ReplyInput";
import ReplyButton from "../common/ReplyButton";
import GenerateButton from "../common/GenerateButton";
import TemplatesButton from "./TemplatesButton";
import { useSelector } from "react-redux";
import { isPaidUser } from "../../utils/firebaseUtil";
import SimilarScore from "./SimilarScore";
import { replyToReview, setReplyText } from "../../utils/apiRequests";

export default function ReviewElement({
  review,
  templateMessages,
  isUsingPlatformApi,
  showTemplateModal,
  onGenerateReply,
}) {
  const [devReplyText, setDevReplyText] = useState("");
  const [devReplyDate, setDevReplyDate] = useState("");
  const [textToReply, setTextToReply] = useState("");
  const [sentiment, setSentiment] = useState("");
  const [similarEmbeddings, setSimilarEmbeddings] = useState(null);
  const [generatedCount, setGeneratedCount] = useState(0);
  const [memoryId, setMemoryId] = useState("");
  const subscriptionType = useSelector((state) => state.subscription.type);

  useEffect(() => {
    setReviewElement(review).then();
  }, [review]);

  async function setReviewElement(reviewJson) {
    setMemoryId(reviewJson.memoryId);
    let devComment = reviewJson.developerComment;
    if (devReplyText || devComment) {
      await setDevReplyText(devReplyText || devComment["text"]);
      await setDevReplyDate(
        devReplyDate || devComment["lastModified"]["seconds"]
      );
    } else {
      await setGeneratedReply(textToReply, reviewJson["generatedReplies"]);
    }
    // await setSentiment(sentiment || userComment["sentiment"]);
    if (reviewJson["generatedReplies"]) {
      let genReplies = reviewJson["generatedReplies"];
      let lastGenReply = genReplies[genReplies.length - 1];
      await setSimilarEmbeddings(
        similarEmbeddings || lastGenReply["similarMemories"]
      );
      await setGeneratedCount(
        generatedCount || reviewJson["generatedReplies"].length
      );
    }
  }

  async function setGeneratedReply(defaultValue, generatedReplies) {
    if (generatedReplies && generatedReplies.length > 0) {
      let lastGeneratedReply = generatedReplies[generatedReplies.length - 1];
      await setTextToReply(defaultValue || lastGeneratedReply["replyText"]);
    }
  }

  function isGeneratedTextOverflown() {
    return (textToReply || "").length > 350;
  }

  async function handleReplyToReview() {
    await replyToReview(review["_id"], textToReply);
    await setReplyText(memoryId, textToReply);
    setDevReplyText(textToReply);
    setDevReplyDate(Math.floor(Date.now() / 1000));
    setTextToReply("");
  }

  let userComment = review["userComment"];
  return (
    <>
      <div className="mb-2">
        <span className="mr-2">
          <ReviewUserInfo authorName={review["authorName"]} />
        </span>
        <ReviewStars rating={userComment["starRating"]} />
        <MessageSentiment sentiment={sentiment} />
        <SimilarScore similarMemories={similarEmbeddings} />
      </div>
      <div className="mr-[5%] mb-2">
        {userComment && (
          <ReviewText
            text={userComment["originalText"]}
            textTranslated={userComment["text"]}
            languageCode={userComment["reviewerLanguage"]}
            lastModifiedSeconds={userComment["lastModified"]["seconds"]}
          />
        )}
      </div>
      <div className="ml-[5%] mb-2">
        <ReviewReply text={devReplyText} lastModifiedSeconds={devReplyDate} />
      </div>
      {isUsingPlatformApi && (
        <div>
          <div className="flex">
            <ReplyInput
              textToReply={textToReply}
              onReplyChanged={(val) => setTextToReply(val)}
              isGeneratedTextOverflown={isGeneratedTextOverflown}
            />
            <ReplyButton
              sendReply={handleReplyToReview}
              isGeneratedTextOverflown={isGeneratedTextOverflown}
            />
          </div>
          <center className="mx-auto mt-3 block">
            {isPaidUser(subscriptionType) && (
              <GenerateButton
                generatedCount={generatedCount}
                generateReply={onGenerateReply}
              />
            )}
            <TemplatesButton
              templates={templateMessages}
              onTemplateClick={(template) => setTextToReply(template)}
              onShowTemplateModal={() => showTemplateModal()}
            />
          </center>
        </div>
      )}
    </>
  );
}
