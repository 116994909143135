import React from "react";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "./CustomTextarea.scss";

export const CustomTextarea = ({
  label,
  placeholder,
  value,
  onChange,
  name,
  rows,
  cols,
}) => {
  const handleClearClick = () => {
    onChange({ target: { name: name, value: "" } });
  };

  return (
    <div className="custom-textarea-wrapper">
      <textarea
        className="custom-textarea"
        name={name}
        cols={cols}
        rows={rows}
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
      ></textarea>
      <div className="custom-textarea-buttons">
        <IconButton onClick={handleClearClick}>
          <ClearIcon />
        </IconButton>
      </div>
    </div>
  );
};
