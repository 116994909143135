import React from "react";
import MemoryElement from "../apps/MemoryElement";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { TooltipProps } from "@mui/material";

const HtmlTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function SimilarScore({ similarMemories }) {
  function getSimilarityClass(similarityScore) {
    let bgColor;
    if (similarityScore > 92) {
      bgColor = "bg-green-500 text-white";
    } else if (similarityScore > 75) {
      bgColor = "bg-yellow-500 text-black";
    } else {
      bgColor = "bg-red-500 text-white";
    }
    return bgColor;
  }

  function getPercentage(distance) {
    return ((1.0 - distance) * 100).toFixed(1);
  }

  if (similarMemories && similarMemories.length > 0) {
    let highestScore = similarMemories[0];
    let scorePercent = getPercentage(highestScore._additional.distance);
    return (
      <>
        <HtmlTooltip
          title={
            <React.Fragment>
              {similarMemories.map((sMemory) => (
                <div key={sMemory._additional.id} className="flex">
                  <div>{getPercentage(sMemory._additional.distance)}%</div>
                  <div className="flex-grow">
                    <MemoryElement
                      memory={sMemory}
                      onMemoryChanged={() => {}}
                      onUserStoppedTyping={() => {}}
                      onDeleteMemory={() => {}}
                    />
                  </div>
                </div>
              ))}
            </React.Fragment>
          }
        >
          <span
            className={`mx-1 px-2 py-1 ${getSimilarityClass(
              scorePercent
            )} small float-end rounded-lg`}
          >
            {scorePercent}% similar
          </span>
        </HtmlTooltip>
      </>
    );
  }
}
