import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import SimpleSpinner from "./SimpleSpinner";

export default function ReplyButton({ sendReply, isGeneratedTextOverflown }) {
  const [isReplying, setReplying] = useState(false);

  async function handleReplyToReview() {
    setReplying(true);
    await sendReply();
    setReplying(false);
  }

  return (
    <button
      className="main-button mx-3 cursor-pointer self-center p-2 text-[1.5rem] text-primary disabled:cursor-not-allowed disabled:opacity-50"
      disabled={isGeneratedTextOverflown()}
    >
      {isReplying ? (
        <SimpleSpinner />
      ) : (
        <FontAwesomeIcon
          className=""
          icon={faPaperPlane}
          type="submit"
          onClick={handleReplyToReview}
        />
      )}
    </button>
  );
}
