import React, { useEffect, useState } from "react";
import { auth, isLoggedIn, isPaidUser } from "../utils/firebaseUtil";
import { listPlatforms } from "../utils/apiRequests";
import AppThumbnail from "../components/dashboard/AppThumbnail";
import VerifyEmailText from "../components/dashboard/VerifyEmailText";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid } from "@mui/material";
import Welcome from "../components/dashboard/Welcome";
import ChromeExtWelcome from "./ChromeExtWelcome";
import { Playground } from "../components/dashboard/playground/Playground";
import { setApps } from "../utils/store-slices/platformsSlice";
import { Platforms } from "../components/dashboard/Platforms";
import Feedback from "../components/dashboard/Feedback";

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const apps = useSelector((state) => state.platforms.apps);
  const subscriptionType = useSelector((state) => state.subscription.type);
  const dispatch = useDispatch();

  useEffect(() => {
    listPlatforms().then(async (result) => {
      dispatch(setApps(result));
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {}, [isLoading]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isLoggedIn()) {
    return <div>Login to continue!</div>;
  }

  if (auth.currentUser.emailVerified || isPaidUser(subscriptionType)) {
    if (apps.length) {
      return (
        <>
          <Playground />
          <Platforms />
          <Feedback />
        </>
      );
    } else {
      return (
        <div>
          <Playground />
          <Welcome />
          <ChromeExtWelcome />
        </div>
      );
    }
  } else {
    return <VerifyEmailText />;
  }
}
