import React from "react";
import { useNavigate } from "react-router-dom";
import "./AppThumbnail.scss";

export default function AppThumbnail({ app }) {
  const navigate = useNavigate();

  return (
    <div
      className="app-thumbnail"
      onClick={() => navigate(`/apps/${app["_id"]}/reviews`)}
      role="button"
    >
      <span style={{ flexGrow: 1 }}>
        {app["displayName"] || app["packageName"]}
      </span>
    </div>
  );
}
