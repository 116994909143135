import { useMediaQuery } from "@mui/material";
import "./ImageTextContent.scss";

function ImageTextContent({ imagePosition, title, text, imageUrl }) {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div className={`image-text-content ${isMobile ? "mobile" : ""}`}>
      <img
        src={imageUrl}
        alt=""
        className="image-text-content__image"
        style={{ order: imagePosition === "left" ? 0 : 1 }}
      ></img>
      <div className="image-text-content__text">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default ImageTextContent;
