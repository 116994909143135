import React, { useRef } from "react";
import Hero from "../components/home/Hero";
import Facts from "../components/home/Facts";
import Works from "../components/home/Works";
import Newsletter from "../components/home/Newsletter";
import Footer from "../components/layout/Footer";
import { isLoggedIn } from "../utils/firebaseUtil";
import Dashboard from "./Dashboard";
// import FAQ from "../components/home/FAQ";
import Benefits from "../components/home/Benefits";
import FooterHero from "../components/home/FooterHero";
import Pricing from "../components/home/Pricing";
import SavingsCalculator from "../components/home/SavingsCalculator";
import WorksStepper from "../components/home/WorksStepper";
import ContactUs from "../components/home/ContactUs";

export default function Home() {
  const contactRef = useRef(null);

  return (
    <>
      <Hero />
      <SavingsCalculator />
      <Benefits />
      {/* <Facts /> */}
      {/* <Works /> */}
      <WorksStepper />
      {/* <FAQ /> */}
      {/* <Pricing contactRef={contactRef} /> */}
      <ContactUs ref={contactRef} />
      <FooterHero />
      <Footer />
    </>
  );
}
