import {
  faArrowPointer,
  faHandPointer,
  faICursor,
  faPaperPlane,
  faUserLarge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@mui/material";
import React from "react";
import "./Tutorial.scss";

const reply =
  "We apologize that you are having trouble with the app. We would appreciate it if you could provide us with some additional details so that we can help you resolve the issue.";

export default function Tutorial() {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div className={`tutorial-container ${isMobile ? "mobile" : ""}`}>
      <div className="tutorial-user">
        <span className="user-icon">
          <FontAwesomeIcon icon={faUserLarge} />
        </span>
        <span className="user-name">John Doe</span>
      </div>
      <span className="text">
        This app does not work.
        <FontAwesomeIcon className="text-select-icon" icon={faICursor} />
        <div className="context-menu-image"></div>
      </span>

      <FontAwesomeIcon className="pointer-icon" icon={faArrowPointer} />
      <div className="text-select-buttons-container">
        <div className="button-wrapper">
          <button className="generate-button">
            <span className="generate-button-text">Generate</span>
          </button>
          <select id="reply-type-selector" className="selector">
            <option value="review">Review</option>
            <option value="email">Email</option>
            <option value="photo-comments">Photo comments</option>
            <option value="user-chat">User chat</option>
          </select>
          <select id="reply-tone-selector" className="selector">
            <option value="customer-support">Customer support</option>
            <option value="official">Official</option>
            <option value="friendly">Friendly / Indirect</option>
            <option value="funny">Funny / Ironic</option>
          </select>
        </div>
        <input
          className="extra-information"
          type="text"
          placeholder="Information to include"
        />
        <div className="generated-text-container">
          <p id="generated-text-paragraph" className="generated-text-paragraph">
            {reply}
          </p>
          <button id="copy-generated-text-button" className="copy-button">
            <svg className="copy-icon" viewBox="0 0 24 24">
              <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
            </svg>
          </button>
        </div>
      </div>
      <div className="reply-input-container">
        <div className="reply-input">{reply}</div>
        <button className="reply-button">
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
      <div className="input-focus-container">
        <button className="paste-button">
          <span className="button__text">Paste</span>
        </button>
        <button className="template-button">Templates</button>
        <button className="add-to-memory-button">
          <span className="button__text">Memorize</span>
        </button>
      </div>
    </div>
  );
}
