import React, { useEffect, useState } from "react";
import {
  createReviewEmbedding,
  generateReviewReply,
  getPlatform,
  getTemplateMessages,
  listReviews,
} from "../utils/apiRequests";
import { useNavigate, useParams } from "react-router-dom";
import MessageFilters from "../components/review/MessageFilters";
import ReviewElement from "../components/review/ReviewElement";
import CustomPagination from "../components/common/CustomPagination";
import TemplateMessageModal from "../components/apps/TemplateMessageModal";
import { playConsoleApi } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import SimpleSpinner from "../components/common/SimpleSpinner";
import { consumeToken } from "../utils/store-slices/subscriptionSlice";
import { useDispatch } from "react-redux";

export default function Reviews() {
  const [app, setApp] = useState(null);
  const [isLoadingReviews, setIsLoadingMessages] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatingIndex, setGeneratingIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterList, setFilterList] = useState([]);
  const [templateMessages, setTemplateMessages] = useState([]);
  const [showTemplates, setShowTemplates] = useState(false);
  let { platformId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getTemplateMessages(platformId).then(setTemplateMessages);
    getPlatform(platformId).then(setApp);
  }, [platformId]);

  useEffect(() => {
    initMessages().then();
  }, [filterList, page]);

  useEffect(() => {}, [reviews]);

  async function initMessages() {
    await setIsLoadingMessages(true);
    let paginatedReviews = await listReviews(platformId, filterList, page);
    setPageCount(paginatedReviews["pages"]);
    setPerPage(paginatedReviews["perPage"]);
    setTotal(paginatedReviews["total"]);
    await extendReviews([], paginatedReviews["reviews"]);
    await setIsLoadingMessages(false);
  }

  async function extendReviews(currentReviews, reviewsResponse) {
    let newReviews = currentReviews.concat(reviewsResponse);
    await setReviews(newReviews);
  }

  async function onGenerateAll() {
    await setIsGenerating(true);
    for (let i = 0; i < reviews.length; i++) {
      await setGeneratingIndex(i);
      if (!reviews[i]["generatedReplies"]) {
        await handleGenerateReply(reviews[i], i);
      }
    }
    await setIsGenerating(false);
  }

  async function handleGenerateReply(review, index) {
    if (!review["memoryId"]) {
      await createReviewEmbedding(review["_id"]);
    }
    let response = await generateReviewReply(review["_id"]);
    let newReviews = [...reviews];
    newReviews[index] = response;
    await setReviews(newReviews);
    dispatch(consumeToken());
  }

  return (
    <>
      <center className="h3 p-3">
        {app && app["clientType"] === playConsoleApi && (
          <input
            type="button"
            className="main-button mx-2 inline-block border"
            disabled={isGenerating}
            onClick={() => onGenerateAll()}
            value={
              isGenerating
                ? `Generating ${generatingIndex + 1}/${reviews.length}`
                : `Generate ${perPage}`
            }
          />
        )}
        <span> {app && (app["displayName"] || app["packageName"])} </span>
        <FontAwesomeIcon
          icon={faGear}
          className="px-1"
          role="button"
          onClick={() => navigate(`/apps/${platformId}/app-settings`)}
        />
      </center>
      <div className="m-auto flex w-[80%] justify-end">
        <div className="my-auto mr-5">
          <span>Total: {total}</span>
        </div>
        <MessageFilters filterList={filterList} setFilterList={setFilterList} />
      </div>
      {isLoadingReviews && (
        <center>
          <SimpleSpinner />
        </center>
      )}
      {!isLoadingReviews &&
        reviews.map((review, index) => {
          return (
            <div key={review["_id"]} className="m-auto w-[80%]">
              <div className="p-7">
                <ReviewElement
                  review={review}
                  templateMessages={templateMessages}
                  isUsingPlatformApi={
                    app && app["clientType"] === playConsoleApi
                  }
                  showTemplateModal={() => setShowTemplates(true)}
                  onGenerateReply={() => handleGenerateReply(review, index)}
                />
              </div>
              <hr className="border-1 border-dark border" />
            </div>
          );
        })}

      {!isLoadingReviews && pageCount > 1 && (
        <div className="py-3">
          <CustomPagination
            pageCount={pageCount}
            page={page}
            setPage={setPage}
          />
        </div>
      )}

      <TemplateMessageModal
        showModal={showTemplates}
        setShowModal={(val) => setShowTemplates(val)}
        onTemplatesSaved={(newTemplateMessages) =>
          setTemplateMessages(newTemplateMessages)
        }
      />
    </>
  );
}
