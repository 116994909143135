import {
  GoogleAuthProvider,
  OAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, isLoggedIn } from "../utils/firebaseUtil";
import React, { useCallback, useEffect, useState } from "react";
import { getActiveSubscription, syncUser } from "../utils/apiRequests";
import google from "../images/google.png";
import micro from "../images/micro.png";
import { sendCustomToExtensions } from "../utils/chromeExtensionUtil";
import { sleep } from "../utils/dataParser";
import {
  setSubscriptionType,
  setTokens,
} from "../utils/store-slices/subscriptionSlice";
import { useDispatch } from "react-redux";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const providerMicrosoft = new OAuthProvider("microsoft.com");
  const [showPage, setShowPage] = useState(false);
  const dispatch = useDispatch();

  async function onLoginHandler() {
    try {
      if (isLoggedIn()) {
        await auth.currentUser.getIdToken(true);
        await sleep(1000); // otherwise getting error: Token used too early. Check that your computer's clock is set correctly.
        await syncUser();
        getActiveSubscription().then((subscription) => {
          dispatch(setSubscriptionType(subscription.type));
          dispatch(setTokens(subscription.tokens));
        });
        await sendCustomToExtensions();
        navigate("/");
      }
    } catch (err) {
      alert(err.message);
    } finally {
      setShowPage(true);
    }
  }

  const logInWithEmailAndPassword = async function (email, password) {
    await signInWithEmailAndPassword(auth, email, password).catch(
      async function (error) {
        await setError(error);
        alert(error);
      }
    );
    await onLoginHandler();
  };

  const signupWithGoogle = async function () {
    await signInWithPopup(auth, provider);
    await onLoginHandler();
  };

  const signupWithMicrosoft = async () => {
    try {
      await signInWithPopup(auth, providerMicrosoft);
      await sendEmailVerification(auth.currentUser);
      navigate("/apps");
    } catch (err) {
      alert(err.message);
    }
  };

  const sendResetLink = async function () {
    await sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    onLoginHandler();
  }, []);

  return (
    <>
      {" "}
      {showPage && (
        <div className="flex min-h-screen bg-primary25">
          <div className="mx-auto mt-16 flex max-w-sm flex-1 flex-col md:mt-32">
            <div className="w-full rounded bg-white px-6 py-8 text-black shadow-md">
              <h1 className="mb-5 text-center text-[2rem]">Login</h1>
              <button
                className="main-button mx-auto my-2 border border-primary"
                onClick={() => signupWithGoogle()}
              >
                <img className="mr-3 ml-1 w-5" src={google} alt="google logo" />
                <span className="text-[1.1rem]"> Sign in with Google</span>
              </button>
              <button
                className="main-button mx-auto my-2 border border-primary"
                onClick={() => signupWithMicrosoft()}
              >
                <img className="mr-3 ml-1 w-5" src={micro} alt="google logo" />
                <span className="text-[1.1rem]"> Sign in with Microsoft</span>
              </button>

              <hr className="my-5" />
              <input
                type="text"
                className="mb-4 block w-full rounded border border-gray-200 p-3"
                name="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <input
                type="password"
                className="mb-4 block w-full rounded border border-gray-200 p-3"
                name="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <button
                type="submit"
                className="main-button mx-auto mt-5 bg-primary text-white"
                onClick={() => logInWithEmailAndPassword(email, password)}
              >
                Login
              </button>
              <center
                onClick={() => sendResetLink()}
                className="mt-3 cursor-pointer text-blue-500 hover:text-blue-600"
              >
                Forgot Password?
              </center>
            </div>
            <center className="mt-5">
              <span className="inline-block text-gray-600">
                Don't have an account?
              </span>
              <input
                type="button"
                className="main-button mx-2 inline-block border border-primary"
                onClick={() => navigate("/signup")}
                value="Sign up"
              />
            </center>
          </div>
        </div>
      )}
    </>
  );
}
