import TemplateMessageElement from "./TemplateMessageElement";
import React, { useEffect, useState } from "react";
import {
  deleteTemplateMessage,
  getTemplateMessages,
  insertTemplateMessage,
  updateTemplateMessage,
} from "../../utils/apiRequests";
import { useParams } from "react-router-dom";

export default function TemplatesEditor({ onTemplatesSaved }) {
  const [templateMessages, setTemplateMessages] = useState([]);
  let { platformId } = useParams();

  useEffect(() => {
    loadTemplates();
  }, [platformId]);

  function loadTemplates() {
    getTemplateMessages(platformId).then(setTemplateMessages);
  }

  return (
    <div className="w-[100%] text-center">
      <center>
        <button
          className="main-button mx-5 inline-block rounded-xl border px-4 py-1"
          onClick={async () => {
            let newTemplate = {
              name: "",
              templateVariations: ["", "", ""],
            };
            await insertTemplateMessage(platformId, newTemplate);
            loadTemplates();
          }}
        >
          +
        </button>
      </center>
      {templateMessages.map((templateMessage, index) => (
        <TemplateMessageElement
          key={templateMessage._id}
          template={templateMessage}
          onTemplateChanged={(key, value) => {
            let newTemplates = [...templateMessages];
            newTemplates[index][key] = value;
            setTemplateMessages(newTemplates);
          }}
          onUserStoppedTyping={async () => {
            await updateTemplateMessage(templateMessage);
            onTemplatesSaved();
          }}
          onDeleteTemplate={async () => {
            await deleteTemplateMessage(templateMessage._id);
            loadTemplates();
          }}
        />
      ))}
    </div>
  );
}
