import React from "react";
import "./Hero.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { extDomain } from "../../config";
import Tutorial from "./Tutorial";
import { Grid, useMediaQuery } from "@mui/material";

function Hero() {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Grid
      container
      className={`hero-container ${isMobile ? "mobile" : ""} `}
      style={{ background: "white" }}
    >
      <Grid className="hero-content" item xs={12} md={6} lg={6} xl={6}>
        <div>
          <h1>
            Get instant<br></br>
            <span className="headline">AI-powered replies</span> to customer
            reviews
          </h1>
        </div>
        <p>
          Revolutionize your customer review management with ReplyBuddy's AI
          technology. Get professional and{" "}
          <span className="highlight">personalized replies </span>
          <br></br>in <span className="highlight">any language</span> with just
          <span className="highlight"> one click</span>. Improve your online
          reputation, enhance customer satisfaction, and grow your business with
          ReplyBuddy - try it today!
        </p>
        <a
          href={extDomain}
          target="_blank"
          className="main-button ml-2 bg-primary text-white"
        >
          <span>
            <FontAwesomeIcon icon={faAdd} className="mr-3" /> Get Chrome
            Extension
          </span>
        </a>
      </Grid>
      <Grid className="hero-tutorial-wrapper" item xs={12} md={6} lg={6} xl={6}>
        <Tutorial className="tutorial" />
        <div className="tutorial-clone-1"></div>
        <div className="tutorial-clone-2"></div>
      </Grid>
    </Grid>
  );
}
export default Hero;
