import MediaUrl from "../common/MediaUrl";

export default function SupportedPlatforms() {
  let platforms = [
    {
      name: "PlayStore",
      image: require("../../images/play-store-logo.png"),
      url: "https://play.google.com/console/u/0/developers",
    },
    {
      name: "AppStore",
      image: require("../../images/app-store-logo.png"),
      url: "https://appstoreconnect.apple.com/apps",
    },
  ];

  return (
    <div>
      {platforms.map(({ image, name, url }) => (
        <MediaUrl key={name} url={url} icon={image} text={name} />
      ))}
    </div>
  );
}
