import { auth } from "./firebaseUtil";
import axios from "axios";
import { playConsoleApi, apiDomain } from "../config";

async function getRequest(url, useAuth = true) {
  let getRequestInit = {};
  if (useAuth === true) {
    getRequestInit = {
      headers: new Headers({
        Authorization: await auth.currentUser.getIdToken(),
      }),
    };
  }
  const response = await fetch(apiDomain + url, getRequestInit);
  const body = await response.text();
  if (!response.ok) {
    const error = (body && JSON.parse(body).message) || response.statusText;
    return Promise.reject(error);
  }
  const data = body ? JSON.parse(body) : {};
  return data;
}

async function postRequest(url, body, useAuth = true) {
  let formData = new FormData();
  for (let key in body) {
    if (key === "files") {
      body[key].forEach((file) => {
        formData.append(key, file);
      });
    } else {
      formData.append(key, body[key]);
    }
  }
  const requestOptions = {
    method: "POST",
    url: apiDomain + url,
    data: formData,
    headers: {},
  };

  if (useAuth === true) {
    requestOptions.headers.Authorization = await auth.currentUser.getIdToken();
  }
  try {
    let response = await axios(requestOptions);
    return response["data"];
  } catch (error) {
    return { success: false, message: error.response.message };
  }
}

async function postRequestJson(url, body, useAuth = true) {
  let headersObj = {
    "Content-Type": "application/json",
  };
  if (useAuth === true) {
    headersObj["Authorization"] = await auth.currentUser.getIdToken();
  }
  const requestOptions = {
    method: "POST",
    headers: headersObj,
    body: JSON.stringify(body),
  };
  try {
    const response = await fetch(apiDomain + url, requestOptions);
    return await response.json();
  } catch (error) {
    return { success: false, message: error.response.message };
  }
}

function encodeQueryData(data) {
  const ret = [];
  for (let d in data) {
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  }
  return ret.join("&");
}

export async function addNewsletterEmail(email) {
  return await postRequestJson(
    "/api/add-newsletter-email",
    { email: email },
    false
  );
}

export async function getGmailMessages(platformId, query, nextPageToken) {
  return await getRequest(
    `/api/gmail-emails/list-emails?platformId=${platformId}&query=${query}&nextPageToken=${nextPageToken}`,
    true
  );
}

export async function syncUser() {
  return await postRequestJson(`/api/sync-user`, {});
}

export async function getUserChromeExtId() {
  return await getRequest(`/api/chrome-ext/get-ext-id`, true);
}

export async function setUserChromeExtId(chromeExtId) {
  return await postRequestJson(`/api/chrome-ext/set-ext-id`, {
    chromeExtId: chromeExtId,
  });
}

export async function createCustomToken() {
  return await postRequestJson(`/api/create-custom-token`, {});
}

export async function subscribeUser(type) {
  return await postRequestJson(`/api/subscribe`, { type: type });
}

export async function getActiveSubscription() {
  return await getRequest(`/api/get-active-subscription`);
}

export async function getPlatform(platformId) {
  return await getRequest(`/api/platform?platformId=${platformId}`);
}

export async function listPlatforms() {
  return await getRequest(`/api/platforms`);
}

export async function addApp(
  displayName,
  supportEmail,
  packageName,
  serviceAccount
) {
  return await postRequestJson(`/api/platforms/add-play-store`, {
    clientType: playConsoleApi,
    displayName: displayName,
    supportEmail: supportEmail,
    packageName: packageName,
    serviceAccount: serviceAccount,
  });
}

export async function deletePlatform(platformId) {
  return await postRequestJson(`/api/platforms/delete`, {
    platformId: platformId,
  });
}

export async function uploadReviewFile(platformId, file) {
  return await postRequest(`/api/upload-review-file?platformId=${platformId}`, {
    file: file,
  });
}

export async function listReviews(platformId, filterName, page) {
  let queryDict = {
    platformId: platformId,
    page: page,
  };
  if (filterName !== null) {
    queryDict["filter"] = filterName;
  }
  return await getRequest(`/api/reviews?${encodeQueryData(queryDict)}`);
}

export async function listAllReviews(unanswered) {
  let queryDict = {};
  if (unanswered) queryDict["unanswered"] = true;
  return await getRequest(
    `/api/list-all-reviews?${encodeQueryData(queryDict)}`
  );
}

export async function createReviewEmbedding(reviewId) {
  return await postRequestJson(`/api/reviews/create-embedded-memory`, {
    reviewId: reviewId,
  });
}

export async function generateReviewReply(reviewId) {
  return await postRequestJson(`/api/generate-reply`, {
    reviewId: reviewId,
  });
}

export async function replyToReview(reviewId, replyText) {
  return await postRequestJson("/api/reviews/publish-reply", {
    reviewId: reviewId,
    replyText: replyText,
  });
}

export async function listMemories(platformId) {
  return await getRequest(`/api/memories?platformId=${platformId}`);
}

export async function setReplyText(memoryId, replyText) {
  return await postRequestJson(`/api/memories/set-reply-content`, {
    memoryId: memoryId,
    replyContent: replyText,
  });
}

export async function updateMemory(memoryId, userText, replyText) {
  return await postRequestJson(`/api/memories/update-memory`, {
    memoryId: memoryId,
    userText: userText,
    replyText: replyText,
  });
}

export async function deleteMemory(memoryId) {
  return await postRequestJson(`/api/memories/delete-memory`, {
    memoryId: memoryId,
  });
}

export async function addReviewNotes(reviewId, notes) {
  return await postRequestJson("/api/add-reply-notes", {
    reviewId: reviewId,
    notes: notes,
  });
}

export async function getTemplateMessages(platformId) {
  let queryDict = { platformId: platformId };
  return await getRequest(
    `/api/template-messages?${encodeQueryData(queryDict)}`
  );
}

export async function insertTemplateMessage(platformId, template) {
  return await postRequestJson(`/api/template-messages/add`, {
    platformId: platformId,
    template: template,
  });
}

export async function updateTemplateMessage(template) {
  return await postRequestJson(`/api/template-messages/update`, {
    template: template,
  });
}

export async function deleteTemplateMessage(templateId) {
  return await postRequestJson(`/api/template-messages/delete`, {
    templateId: templateId,
  });
}

export async function listFineTuning() {
  return await getRequest(`/api/list-fine-tuning`);
}

export async function createFineTuningModel(modelName) {
  return await postRequestJson(`/api/create-fine-tuning-model`, {
    modelName: modelName,
  });
}

export async function deleteFineTuningModel(modelId) {
  return await postRequestJson(`/api/delete-fine-tuning-model`, {
    modelId: modelId,
  });
}

export async function startFineTuning(modelId) {
  return await postRequestJson(`/api/start-fine-tuning`, {
    modelId: modelId,
  });
}

export async function getFineTuningProcess(modelId) {
  return await getRequest(`/api/get-fine-tuning-process?modelId=${modelId}`);
}

export async function getTrainingData(fineTuningId) {
  let queryDict = { fineTuningId: fineTuningId };
  return await getRequest(
    `/api/get-training-data?${encodeQueryData(queryDict)}`
  );
}

export async function insertTrainingData(fineTuningId, before, after) {
  return await postRequestJson(`/api/insert-training-data`, {
    fineTuningId: fineTuningId,
    trainingData: {
      before: before,
      after: after,
    },
  });
}

export async function updateTrainingData(trainingData) {
  return await postRequestJson(`/api/update-training-data`, {
    trainingData: trainingData,
  });
}

export async function deleteTrainingData(trainingDataId) {
  return await postRequestJson(`/api/delete-training-data`, {
    trainingDataId: trainingDataId,
  });
}

export async function listOpenAiFiles() {
  return await getRequest(`/api/list-open-ai-files`);
}

export async function getOpenAiFile(fileId) {
  return await getRequest(`/api/get-open-ai-file?fileId=${fileId}`);
}

export async function deleteOpenAiFile(fileId) {
  return await postRequestJson(`/api/delete-open-ai-file`, {
    fileId: fileId,
  });
}

export async function listOpenAiFineTunedModels() {
  return await getRequest(`/api/list-open-ai-fine-tuned-models`);
}

export async function deleteOpenAiFineTunedModel(modelId) {
  return await postRequestJson(`/api/delete-open-ai-fine-tuned-model`, {
    modelId: modelId,
  });
}

export async function addMailMemory(gmailId, subject, userText, replyText) {
  return await postRequestJson(`/api/gmail-memories/add-memory`, {
    gmailId: gmailId,
    subject: subject,
    userText: userText,
    replyText: replyText,
  });
}

export async function replyToMail(platformId, email, textToReply) {
  return await postRequestJson("/api/gmail-emails/publish-reply", {
    platformId,
    email,
    textToReply,
  });
}

export async function generateMailReply(email, platformId) {
  return await postRequestJson(`/api/generate-gmail-reply`, {
    email: email,
    platformId,
  });
}

export async function generateMissingGmailMemoryEmbeddings(platformId) {
  return await postRequestJson(
    `/api/gmail-memories/generate-missing-embeddings`,
    {
      platformId: platformId,
    }
  );
}
export async function generateReplyPlayground(payload) {
  return await postRequestJson(`/api/playground/generate-reply`, {
    ...payload,
  });
}

export async function sendMail(formData, useAuth = true) {
  return await postRequest(`/api/mails/send-mails`, formData, useAuth);
}
