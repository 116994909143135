import { Paper } from "@mui/material";

export default function EmailReceived({ replyText }) {
  return (
    <Paper
      style={{
        overflow: "hidden",
        overflowWrap: "break-word",
        backgroundColor: "#804eea",
        padding: "20px",
        borderRadius: "30px 0px 30px 30px",
        maxWidth: "85%",
        border: "1px solid #dbdbdb",
        alignSelf: "flex-end",
      }}
      elevation={0}
    >
      {replyText}
    </Paper>
  );
}
