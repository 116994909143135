import { configureStore } from "@reduxjs/toolkit";
import subscriptionReducer from "./utils/store-slices/subscriptionSlice";
import platformsReducer from "./utils/store-slices/platformsSlice";

export default configureStore({
  reducer: {
    subscription: subscriptionReducer,
    platforms: platformsReducer,
  },
});
