import React, { useState } from "react";
import { addApp } from "../utils/apiRequests";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

export default function AddApp() {
  const [isLoading, setIsLoading] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [packageName, setPackageName] = useState("");
  const [serviceAccountJson, setServiceAccountJson] = useState("");
  const navigate = useNavigate();

  function isDisplayNameOverflown() {
    return (displayName || "").length > 30;
  }

  async function onAddApp() {
    setIsLoading(true);
    let appResponse = await addApp(
      displayName,
      supportEmail,
      packageName,
      serviceAccountJson
    );
    if (appResponse["success"]) {
      navigate("/apps");
    } else {
      alert(`Failed to add app:\n${appResponse["message"]}`);
    }
    setIsLoading(false);
  }

  return (
    <>
      <center className="fw-bold fs-1-8 my-5">
        <img
          className="w-15"
          alt="reply example"
          src={require("../images/play-store-logo.png")}
        />
        <span>Let's add an Android app!</span>
      </center>
      <div className="mx-auto grid w-[50rem] grid-cols-4 gap-y-4 gap-x-2">
        <label>App Name</label>
        <div className="col-span-2">
          <input
            type="text"
            className="w-full rounded-md border border-gray-300 px-2 py-1"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          {displayName && (
            <span
              className={`float-end small mt-1 ${
                isDisplayNameOverflown() && "color-red"
              }`}
            >
              {(displayName || "").length} / 30
            </span>
          )}
        </div>
        <div />

        <label>Package Name</label>
        <input
          type="text"
          className="col-span-2 w-full rounded-md border border-gray-300 px-2 py-1"
          value={packageName}
          onChange={(e) => setPackageName(e.target.value)}
        />
        <Tooltip
          title="It is specified in your app's build.gradle"
          className=""
        >
          <IconButton>
            <FontAwesomeIcon icon={faQuestion} />
          </IconButton>
        </Tooltip>

        <label>Support email</label>
        <input
          type="text"
          className="col-span-2 w-full rounded-md border border-gray-300 px-2 py-1"
          value={supportEmail}
          onChange={(e) => setSupportEmail(e.target.value)}
        />
        <Tooltip
          title="AI would recommend users to write to this email in case of questions"
          className=""
        >
          <IconButton>
            <FontAwesomeIcon icon={faQuestion} />
          </IconButton>
        </Tooltip>

        <label>Service Account JSON</label>
        <textarea
          rows={5}
          className="col-span-2 w-full rounded-md border border-gray-300 px-2 py-1"
          value={serviceAccountJson}
          onChange={(e) => setServiceAccountJson(e.target.value)}
        />
        <div className="text-center">
          <span
            className="my-auto cursor-pointer text-center"
            onClick={() => window.open("/create-play-credentials", "_blank")}
          >
            How to?
          </span>
        </div>
      </div>
      <input
        type="button"
        className="mx-auto mt-5 block rounded border border-gray-500 bg-primary px-4 py-2 text-white"
        disabled={
          isLoading ||
          isDisplayNameOverflown() ||
          !displayName ||
          !supportEmail ||
          !packageName ||
          !serviceAccountJson
        }
        onClick={!isLoading ? () => onAddApp() : null}
        value={isLoading ? "Saving..." : "Save"}
      />
    </>
  );
}
