import "./Playground.scss";
import { Grid, useMediaQuery } from "@mui/material";
import { GenerateReplyForm } from "./GenerateReplyForm";
import { extDomain } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

export const Playground = ({}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Grid container className={`playground ${isMobile ? "mobile" : ""} `}>
      <Grid className="playground-info" item xs={12} md={5} lg={5} xl={5}>
        <h1 className="playground-title">Playground</h1>
        <p className="playground-text-title">
          Welcome to the ReplyBuddy Playground!
        </p>
        <p className="playground-text">
          Here, you can use ReplyBuddy as a simple copy-paste tool to reply to
          your reviews. While this is a convenient option, we highly encourage
          you to download the ReplyBuddy Chrome Extension to experience a
          smoother and more efficient review management process.
        </p>
        <p>
          With the extension, you'll be able to use ReplyBuddy directly on the
          website where you answer your reviews - making the process even more
          streamlined. Give it a try and see the difference it makes in your
          review management workflow!
        </p>
        <a
          href={extDomain}
          target="_blank"
          className="main-button ml-2 bg-white text-black"
        >
          <span>
            <FontAwesomeIcon icon={faAdd} className="mr-3" /> Get Chrome
            Extension
          </span>
        </a>
      </Grid>
      <Grid className="playground-form" item xs={12} md={7} lg={7} xl={7}>
        <GenerateReplyForm />
      </Grid>
    </Grid>
  );
};
