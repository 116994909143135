import React, { useEffect, useState } from "react";
import {
  deleteMemory,
  listMemories,
  updateMemory,
} from "../../utils/apiRequests";
import { useParams } from "react-router-dom";
import MemoryElement from "./MemoryElement";

export default function MemoryEditor({ onMemoriesSaved }) {
  const [memories, setMemories] = useState([]);
  let { platformId } = useParams();

  useEffect(() => {
    loadMemories();
  }, [platformId]);

  function loadMemories() {
    listMemories(platformId).then(setMemories);
  }

  return (
    <div className="w-[100%]">
      {/*<center>*/}
      {/*  <button*/}
      {/*    className="main-button mx-5 inline-block rounded-xl border px-4 py-1"*/}
      {/*    onClick={async () => {*/}
      {/*      await addReviewMemory(platformId, 5, "", "");*/}
      {/*      loadMemories();*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    +*/}
      {/*  </button>*/}
      {/*</center>*/}
      {/*<br />*/}

      {memories.map((memory, index) => {
        let memoryId = memory._additional.id;
        return (
          <MemoryElement
            key={memoryId}
            memory={memory}
            onMemoryChanged={(key, value) => {
              let newMemories = [...memories];
              newMemories[index][key] = value;
              setMemories(newMemories);
            }}
            onUserStoppedTyping={async () => {
              await updateMemory(
                memoryId,
                memory.userContent,
                memory.replyContent
              );
              onMemoriesSaved();
            }}
            onDeleteMemory={async () => {
              await deleteMemory(memoryId);
              loadMemories();
            }}
          />
        );
      })}
    </div>
  );
}
