/*global chrome*/
import {createCustomToken, getUserChromeExtId} from "./apiRequests";

async function sendMessageToExtensions(extId, message) {
  try {
    chrome.runtime.sendMessage(extId, message, function (response) {
      if (!response || !response.success) {
        return response;
      }
    });
  } catch (e) {
    console.log(e);
  }
}

export async function sendCustomToExtensions() {
  const response = await createCustomToken();
  const { chromeExtId } = await getUserChromeExtId();
  await sendMessageToExtensions(chromeExtId, {
    command: "customToken",
    data: response.customToken,
  });
}
