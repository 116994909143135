import { IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { sendMail } from "../../utils/apiRequests";
import { CustomTextField } from "../common/CustomTextField";
import "./Feedback.scss";
import RoomServiceOutlined from "@mui/icons-material/RoomServiceOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import FeedbackOutlined from "@mui/icons-material/FeedbackOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import SimpleSpinner from "../common/SimpleSpinner";

const Feedback = () => {
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [files, setFiles] = useState(null);
  const [open, setOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFeedbackType(null);
    setEmail("");
    setText("");
    setFiles(null);
    setErrorMessage(null);
    setSuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(null);
    setSuccess(false);
    const MAX_FILE_SIZE = 25 * 1024 * 1024;
    let error = false;
    const emailData = {
      email,
      firstName,
      lastName,
      text,
      mailType: feedbackType,
    };

    if (files) {
      files.forEach((file) => {
        if (file.size > MAX_FILE_SIZE) {
          setErrorMessage("File size is greater than maximum limit, 25MB");
          error = true;
          return;
        }
      });
      emailData.files = files;
      if (error) {
        setLoading(false);
        return;
      }
    }

    try {
      await sendMail(emailData);
      setSuccess(true);
    } catch (err) {
      setErrorMessage("Something went wrong, please try again later");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFiles(Object.values(e.target.files));
    setErrorMessage(null);
    setSuccess(false);
  };

  return (
    <>
      <IconButton className="feedback-button" onClick={handleOpen}>
        <FeedbackOutlined />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="feedback-form-container">
          {!feedbackType && (
            <>
              <div
                className="feedback-option"
                onClick={() => setFeedbackType("feature request")}
              >
                <RoomServiceOutlined />
                <div className="feedback-option-content">
                  <h1>Feature request</h1>
                  <p>Tell us how we can improve</p>
                </div>
              </div>
              <div
                className="feedback-option"
                onClick={() => setFeedbackType("feedback")}
              >
                <ChatOutlinedIcon />
                <div className="feedback-option-content">
                  <h1>General feedback</h1>
                  <p>Give general feedback</p>
                </div>
              </div>
            </>
          )}
          {feedbackType && (
            <form className="feedback-form" onSubmit={handleSubmit}>
              <CustomTextField
                label="Email"
                value={email}
                type="email"
                required
                focusColor="#642ae5"
                onChange={(e) => setEmail(e.target.value)}
              />
              <CustomTextField
                label="First name"
                value={firstName}
                type="text"
                required
                focusColor="#642ae5"
                onChange={(e) => setFirstName(e.target.value)}
              />
              <CustomTextField
                label="Last name"
                value={lastName}
                type="text"
                required
                focusColor="#642ae5"
                onChange={(e) => setLastName(e.target.value)}
              />
              <textarea
                disabled={false}
                value={text}
                required
                placeholder={`Enter your ${feedbackType} here`}
                onChange={(e) => setText(e.target.value)}
                className="feedback-form__textarea"
              />
              <div>
                <label>Attach a files:</label>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*,video/mp4,video/x-m4v,video/*"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />
                  <CameraAltOutlinedIcon />
                </IconButton>
                {files &&
                  files.map((file, index) => (
                    <div key={`file-${index}`} className="feedback-form__files">
                      {file.name}
                    </div>
                  ))}
              </div>
              {errorMessage && (
                <div className="feedback-form__error">{errorMessage}</div>
              )}
              {success && (
                <div className="feedback-form__success">
                  Thank you for your feedback!
                </div>
              )}
              <button
                className="main-button feedback-form__button"
                type="submit"
              >
                {!loading && "Submit"}
                {loading && <SimpleSpinner />}
              </button>
            </form>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Feedback;
