import React, { forwardRef, useState } from "react";
import { sendMail } from "../../utils/apiRequests";
import { CustomTextField } from "../common/CustomTextField";
import "./ContactUs.scss";
import SimpleSpinner from "../common/SimpleSpinner";
import { useMediaQuery } from "@mui/material";

const ContactUs = forwardRef((props, ref) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [text, setText] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(null);
    setSuccess(false);
    try {
      await sendMail(
        { email, firstName, lastName, text, mailType: "contact mail" },
        false
      );
      setSuccess(true);
    } catch (err) {
      setErrorMessage("Something went wrong, please try again later");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      ref={ref}
      className={`contact-form-container ${isMobile ? "mobile" : ""}`}
    >
      <h1 className="contact-form__title">Not sure about something?</h1>
      <h1 className="contact-form__title">Contact us!</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="contact-form__user-infos">
          <CustomTextField
            className="contact-form__input"
            label="Email"
            value={email}
            type="email"
            required
            focusColor="#642ae5"
            onChange={(e) => setEmail(e.target.value)}
          />
          <CustomTextField
            className="contact-form__input"
            label="First name"
            value={firstName}
            type="text"
            required
            focusColor="#642ae5"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <CustomTextField
            className="contact-form__input"
            label="Last name"
            value={lastName}
            type="text"
            required
            focusColor="#642ae5"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <textarea
          disabled={false}
          value={text}
          required
          placeholder={`Enter your message here`}
          onChange={(e) => setText(e.target.value)}
          className="contact-form__textarea"
        />
        {errorMessage && (
          <div className="contact-form__error">{errorMessage}</div>
        )}
        {success && (
          <div className="contact-form__success">
            Thank you for reaching out to us!
          </div>
        )}
        <button
          className="main-button purchase-button contact-form__button bg-primary text-white"
          type="submit"
        >
          {!loading && "Submit"}
          {loading && <SimpleSpinner />}
        </button>
      </form>
    </div>
  );
});

export default ContactUs;
