import { createSlice } from "@reduxjs/toolkit";

export const platformsSlice = createSlice({
  name: "platforms",
  initialState: {
    apps: [],
  },
  reducers: {
    setApps: (state, action) => {
      state.apps = action.payload;
    },
  },
});

export const { setApps } = platformsSlice.actions;

export default platformsSlice.reducer;
