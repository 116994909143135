import React from "react";

export default function ReplyInput({
  textToReply,
  onReplyChanged,
  isGeneratedTextOverflown,
  maxLength = 350,
}) {
  return (
    <div className="relative inline-block flex-grow">
      <textarea
        rows={3}
        placeholder="Reply"
        className="w-[100%] rounded-xl border border-gray-200 px-2 py-1"
        value={textToReply || ""}
        onChange={(e) => onReplyChanged(e.target.value)}
      />
      <span
        className={`absolute right-0 bottom-[-1rem] text-[0.88rem] ${
          isGeneratedTextOverflown() && "text-red-500"
        }`}
      >
        {(textToReply || "").length} / {maxLength}
      </span>
    </div>
  );
}
