import React from "react";
import "./FooterHero.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { extDomain } from "../../config";
import { useMediaQuery } from "@mui/material";

function FooterHero() {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div className={`footer-hero ${isMobile ? "mobile" : ""}`}>
      <div className="content">
        <h1 className="headline">Say goodbye to reviews chaos.</h1>
        <h1 className="headline">Get Chrome Extensions</h1>
      </div>

      <a
        href={extDomain}
        target="_blank"
        className="main-button ml-2 bg-primary text-white"
      >
        <span>
          <FontAwesomeIcon icon={faAdd} className="mr-3" /> Get Chrome Extension
        </span>
      </a>
    </div>
  );
}
export default FooterHero;
