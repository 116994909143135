import React, { useState } from "react";
import "./Pricing.scss";
import { Grid, Tab, Tabs, useMediaQuery } from "@mui/material";
import { PricingCard } from "../common/PricingCard";

function Pricing({ contactRef }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div className={`pricing-container ${isMobile ? "mobile" : ""} `}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="as"
        textColor="inherit"
        variant="fullWidth"
        sx={{ mb: 4, display: "flex", justifyContent: "center" }}
      >
        <Tab
          sx={{
            color: value ? "#642ae5" : "white",
            maxWidth: "200px",
            background: value ? "" : "#804eea",
            borderRadius: "10px",
            border: "1px solid #642ae5",
            mr: "10px",
          }}
          label="Monthly subsciptions"
        />

        <Tab
          sx={{
            color: value ? "white" : "#642ae5",
            maxWidth: "200px",
            background: value ? "#804eea" : "",
            borderRadius: "10px",
            border: "1px solid #642ae5",
            ml: "10px",
          }}
          label="Yearly subsciptions"
        />
      </Tabs>
      <h1 className="limited-time-offers">Limited time offers</h1>
      <Grid container className={`pricing-grid ${isMobile ? "mobile" : ""} `}>
        {value === 0 && (
          <>
            <Grid className="pricing-col" item xs={12} md={3} lg={3} xl={3}>
              <PricingCard
                title="Pay per use"
                price="0.15"
                fullPrice={"$0.3/reply"}
                timeInterval="/reply"
                facts={["Flexibility"]}
                replies="Unlimited"
                buttonText="Get started"
              />
            </Grid>
            <Grid className="pricing-col" item xs={12} md={3} lg={3} xl={3}>
              <PricingCard
                title="Small"
                price="10"
                fullPrice={"$20/month"}
                timeInterval="/month"
                replies="100"
                buttonText="Subscribe"
                facts={["0.10$ per extra reply"]}
              />
            </Grid>
            <Grid className="pricing-col" item xs={12} md={3} lg={3} xl={3}>
              <PricingCard
                title="Medium"
                price="15"
                fullPrice={"$30/month"}
                timeInterval="/month"
                facts={["0.15$ per extra reply"]}
                replies="300"
                buttonText="Subscribe"
              />
            </Grid>
            <Grid
              className="pricing-col large"
              item
              xs={12}
              md={3}
              lg={3}
              xl={3}
            >
              <PricingCard
                title="Large"
                price="25"
                fullPrice={"$50/month"}
                timeInterval="/month"
                facts={["0.25$ per extra reply"]}
                replies="500"
                buttonText="Subscribe"
              />
            </Grid>
          </>
        )}
        {value === 1 && (
          <>
            <Grid className="pricing-col" item xs={12} md={3} lg={3} xl={3}>
              <PricingCard
                title="Small annual plan"
                noExtraTitleUnderLine={true}
                price="100"
                fullPrice={"$200/year"}
                timeInterval="/year"
                replies="1200"
                facts={[
                  "2 months free",
                  "100 monthly replies",
                  "0.10$ per extra reply",
                ]}
                buttonText="Buy"
              />
            </Grid>
            <Grid className="pricing-col" item xs={12} md={3} lg={3} xl={3}>
              <PricingCard
                title="Medium annual plan"
                price="150"
                fullPrice={"$300/year"}
                timeInterval="/year"
                facts={[
                  "2 months free",
                  "300 monthly replies",
                  "0.15$ per extra reply",
                ]}
                replies="3600"
                buttonText="Buy"
              />
            </Grid>
            <Grid
              className="pricing-col large"
              item
              xs={12}
              md={3}
              lg={3}
              xl={3}
            >
              <PricingCard
                title="Large anual plan"
                price="250"
                fullPrice={"$500/year"}
                facts={[
                  "2 months free",
                  "500 monthly replies",
                  "0.25$ per extra reply",
                ]}
                timeInterval="/year"
                replies="6000"
                buttonText="Buy"
              />
            </Grid>
            <Grid className="pricing-col" item xs={12} md={3} lg={3} xl={3}>
              <PricingCard
                buttonText={"Contact us"}
                buttonFunction={() =>
                  contactRef.current.scrollIntoView({ behavior: "smooth" })
                }
                facts={[
                  "For custom deals and enterprise plans feel free to contact us anytime.",
                ]}
                title="Not enough?"
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
export default Pricing;
