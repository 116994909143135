import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getGmailMessages } from "../utils/apiRequests";
import EmailElement from "../components/email/EmailElement";

export default function Emails() {
  const [isLoadingEmails, setIsLoadingEmails] = useState(false);
  const [emails, setEmails] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");
  let { platformId } = useParams();

  useEffect(() => {
    initEmails();
  }, []);

  async function initEmails() {
    setIsLoadingEmails(true);
    let response = await getGmailMessages(platformId, "", nextPageToken);
    setEmails(response.messages);
    setNextPageToken(response.nextPageToken);
    setIsLoadingEmails(false);
  }

  return (
    <>
      {!isLoadingEmails && (
        <div style={{ width: "80%" }} className={"mx-auto my-10"}>
          {emails.map((email) => {
            return <EmailElement key={email.id} email={email} />;
          })}
        </div>
      )}
    </>
  );
}
