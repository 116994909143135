import React from "react";
import "./ChromeExtWelcome.css";

export default function ChromeExtWelcome() {
  const [selectedReview, setSelectedReview] = React.useState("Great product! It has really improved my daily workflow");

  const handleReviewChange = (event) => {
    setSelectedReview(event.target.value);
  };

  return (
    <div className="welcome-page">
      <div className="how-does-it-work">How does the Extension work?</div>

      <div className="highlight-and-generate-text">
        1) Highlight the review text bellow and click the newly appearing
        "Generate" button
      </div>

      <div className="selected-review-box">{selectedReview}</div>
      <div>
        <div className="review-select-text">Change the review for testing:</div>
        <select className="review-select" onChange={handleReviewChange}>
          <option value="Great product! It has really improved my daily workflow">
            Review 1
          </option>
          <option value="I was skeptical at first, but this chrome extension has really made a difference">
            Review 2
          </option>
          <option value="Easy to use and has saved me a lot of time">
            Review 3
          </option>
        </select>
      </div>

      <div className="click-input-text">2) Click to the input field</div>
      <div className="input-text-container">
        <textarea
          className="input-text"
          rows={3}
          placeholder="After clicking here, the 'Paste' button will appear from the extension"
        />
      </div>
      <div className="instructions">
        To enable ReplyBuddy chrome extension, click the popup button on the top
        right corner and set the current page enabled.
      </div>
    </div>
  );
}
