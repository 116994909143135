import SimpleDropdown from "../common/SimpleDropdown";

export default function TemplatesButton({
  templates,
  onTemplateClick,
  onShowTemplateModal,
}) {
  let menuItems = templates
    .map((template) => {
      return {
        label: template.name,
        handleClick: () =>
          onTemplateClick(
            template["templateVariations"][
              Math.floor(Math.random() * template["templateVariations"].length)
            ]
          ),
      };
    })
    .concat([
      {
        label: "✎ Edit Templates",
        handleClick: () => onShowTemplateModal(),
      },
    ]);
  return <SimpleDropdown label="Templates" menuItems={menuItems} />;
}
